(function ($, Drupal) {
  var contentBlockSitewideBanner = {
    init: function (context) {
      var self = this;

      self.$module = $('.js-content-block-sitewide-banner--v1', context);
      if (self.$module.length < 1) {
        return false;
      }
      self.$triggers = $('.js-content-block-sitewide-banner-trigger', self.$module);
      self.initialScrollPosition = $(window).scrollTop();
      self.$body = $('body');
      var disclosureButton = new AccessibleDisclosureButton(self.$triggers.get(0));
      var moduleId = self.$module.data().moduleId;
      var hasModuleCookie = $.cookie(moduleId) > 0 ? true : false;
      var delayDisplay = self.$module.data().delayDisplay || 1;

      if (hasModuleCookie === true) {
        self.$module.detach();

        return;
      }

      // delay display option
      setTimeout(function () {
        if (self.$body.hasClass('elc-user-state-logged-in') === true) {
          self.$module.detach();

          return;
        }

        self.$module.removeClass('hidden');
      }, delayDisplay + '000');

      disclosureButton.init();
      self.$body.addClass('content-block-sitewide-banner--enabled');

      if (self.$triggers.data('expires')) {
        var observer = new MutationObserver(function (mutationList) {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
              self.$body.removeClass('content-block-sitewide-banner--enabled');
              self.setCookie();
              self.$module.detach();
            }
          });
        });

        observer.observe(self.$triggers.get(0), {
          childList: false,
          attributes: true
        });
      }

      self.$triggers.once().on('click', function () {
        self.$module.addClass('hidden');
      });
    },

    hideOnScrollUp: function () {
      var self = this;
      var currentScroll = $(window).scrollTop();

      if (currentScroll <= self.initialScrollPosition) {
        self.$body.removeClass('content-block-sitewide-banner--hidden');
      } else if (currentScroll > 5) {
        self.$body.addClass('content-block-sitewide-banner--hidden');
      }
      self.initialScrollPosition = currentScroll;
    },

    setCookie: function () {
      var self = this;

      $.cookie(self.$triggers.attr('aria-controls'), 1, {
        path: '/',
        expires: self.$triggers.data('expires')
      });
    }
  };

  Drupal.behaviors.contentBlockSitewideBannerV1 = {
    $module: $(),
    $triggers: $(),
    $body: $(),
    initialScrollPosition: 0,
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached === true) {
        return;
      }
      self.attached = true;
      contentBlockSitewideBanner.init(context);
    }
  };

  $(window).on(
    'scroll',
    _.throttle(function () {
      var $window = $(window);
      var $body = $(document).find('body');
      var scrollTopThreshold = $window.height();
      var scrollTop = $window.scrollTop();

      if (scrollTopThreshold < scrollTop) {
        $body.addClass('content-block-sitewide-banner--hidden');
      } else if (scrollTop > scrollTopThreshold) {
        $body.addClass('content-block-sitewide-banner--hidden');
      } else if (scrollTopThreshold > scrollTop) {
        $body.removeClass('content-block-sitewide-banner--hidden');
      }
    }, 33)
  );

  $(document).on('contentBlock.signupSuccess', function () {
    contentBlockSitewideBanner.setCookie();
  });
})(jQuery, Drupal);
